import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import {
  checkUsernameUnique,
  isValidUsername,
  useAuth,
} from "../../../contexts/AuthContext";
import { fsdb } from "../../../firebase";

interface UpdateUsernameFormProps {
  onUsernameUpdated: () => void;
}

const UpdateUsernameForm: React.FC<UpdateUsernameFormProps> = ({
  onUsernameUpdated,
}) => {
  const [username, setUsername] = useState("");
  const { user, updateUsername } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValidUsername(username)) {
      alert("Username can only contain alphabets and numbers");
    } else {
      try {
        const isUnique = await checkUsernameUnique(username);
        if (user) {
          if (isUnique) {
            await updateUsername(username);
            await fsdb
              .collection("displayNames")
              .add({ username, email: user.email });
            onUsernameUpdated();
          } else {
            alert("Username already exists");
          }
        } else {
          alert("User is not logged in");
        }
      } catch (error) {
        console.error("Failed to update username:", error);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="username">
        <Form.Label>Username</Form.Label>
        <Form.Control
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </Form.Group>
      <Button type="submit" className="mt-3">
        Update Username
      </Button>
    </Form>
  );
};

export default UpdateUsernameForm;
