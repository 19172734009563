/* eslint-disable react/no-unknown-property */
// LandingPage.tsx
import React, { Suspense, useState } from "react";
import { Card, Button, Container, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import {
  AppleLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { fsdb } from "../../../firebase";
import Earth from "../../SharedComponents/Earth/earthLogin";
import UpdateUsernameForm from "./UpdateUsernameForm";
import "./preLogin.scss";
import { useAuth } from "../../../contexts/AuthContext";
import { useAlert } from "../../../contexts/AlertContext";
import { logEvent } from "@firebase/analytics";

const LogoTitle = () => (
  <>
    <span className="text-black">Neg</span>
    <span className="text-blue">nan</span>
  </>
);

const EmailLoginButton = ({ onClick }: { onClick: () => void }) => {
  const handleClick = () => {
    logEvent(getAuth(), "login", { method: "email" });
    onClick();
  };

  return (
    <Button className="btn emailButton w-100" onClick={handleClick}>
      Log in with Email
    </Button>
  );
};

const GuestLoginLink = ({ onClick }: { onClick: () => void }) => (
  <div className="mx-auto landing-page-guest-link">
    Continue without logging in?{" "}
    <Link to="#" onClick={onClick}>
      Guest
    </Link>
  </div>
);

const EarthCanvas = () => (
  <Canvas
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    }}
  >
    <Suspense fallback={null}>
      <ambientLight intensity={2} />
      <Earth />
    </Suspense>
  </Canvas>
);

export default function LandingPage() {
  const [showUsernameForm, setShowUsernameForm] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const { loginAsGuest } = useAuth();
  const { showAlert } = useAlert();
  const googleAuthProvider = new GoogleAuthProvider();

  async function handleGuestLogin() {
    try {
      await loginAsGuest();
      logEvent(auth, "login", { method: "guest" });
      navigate("/welcome");
    } catch (error) {
      showAlert("Failed to log in as a guest.");
    }
  }

  function handleEmail() {
    navigate("/login");
  }

  // This code signs the user in with a Google account.
  // It uses the Google Sign-in API to get a Google credential,
  // and then it signs the user in using that credential.
  // It then redirects the user to the home page.
  async function handleGoogleSignIn() {
    try {
      const result = await signInWithPopup(auth, googleAuthProvider);
      const user = result.user;
      if (user.email) {
        const querySnapshot = await fsdb
          .collection("displayNames")
          .where("email", "==", user.email)
          .get();
        if (querySnapshot.empty) {
          setShowUsernameForm(true);
        } else {
          const doc = querySnapshot.docs[0];
          const data = doc.data();
          if (data.username && data.email === user.email) {
            navigate("/welcome");
          } else {
            setShowUsernameForm(true);
          }
        }
      } else {
        setShowUsernameForm(true);
      }
    } catch (error: unknown) {
      const googleErrorCode = (error as any).code;
      const googleErrorMessage = (error as any).message;
      showAlert(googleErrorCode + ": " + googleErrorMessage);
    }
  }

  const handleUsernameUpdated = () => {
    navigate("/welcome");
  };

  return showUsernameForm ? (
    <Container className="mt-5">
      <UpdateUsernameForm onUsernameUpdated={handleUsernameUpdated} />
    </Container>
  ) : (
    <Container fluid className="p-0">
      <div className="earth-card-container">
        <Row className="justify-content-center">
          <Col xs={12} sm={10} md={8} lg={6}>
            <Card className="bg-light text-center landing-page-card card-fixed-width">
              <Card.Body>
                <h2 className="mb-4">
                  <LogoTitle />
                </h2>
                <EmailLoginButton onClick={handleEmail} />
                <GoogleLoginButton onClick={handleGoogleSignIn} />
                <AppleLoginButton />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <GuestLoginLink onClick={handleGuestLogin} />
        <EarthCanvas />
      </div>
    </Container>
  );
}
