// CommentSystem.tsx
import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import CommentBox from './DiscussionComponents/commentBox';
import { CommentComponent } from './DiscussionComponents/commentComponent';
import useCommentSystemContent from './useCommentSystemContent';
import { CommentsData } from './__constants__/comment.types';

interface CommentProps {
  articleId: string;
  commentsData: CommentsData | null;
  displayName: string;
}

export const CommentSystem: React.FC<CommentProps> = ({
  articleId,
  commentsData,
  displayName,
}) => {
  const {
    handleAddComment,
    handleDeleteComment,
    handleDeleteReply,
    handleAddReply,
    handleUpvote,
    sortedComments,
    newComment,
    setNewComment,
    newReply,
    replyId,
    setNewReply,
  } = useCommentSystemContent(articleId, displayName);

  const [visibleCommentsCount, setVisibleCommentsCount] = useState(10);

  const [openReplies, setOpenReplies] = useState<{
    [key: string]: { isOpen: boolean; shownReplies: number };
  }>({});

  const [showReplyBox, setShowReplyBox] = useState<{ [key: string]: boolean }>(
    {},
  );

  const isAnonymous = displayName === 'Anonymous';

  // Function to show more comments
  const showMoreComments = () => {
    setVisibleCommentsCount((prevCount) => prevCount + 10);
  };

  return (
    <Container style={{ marginTop: '10%' }}>
      <Row className='justify-content-center'>
        <Col xs={12} md={8} className='commentSystemWrapper'>
          {!isAnonymous ? (
            <CommentBox
              newComment={newComment}
              setNewComment={setNewComment}
              handleAddComment={handleAddComment}
            />
          ) : null}
          {sortedComments
            .slice(0, visibleCommentsCount)
            .map((commentId) =>
              commentsData && commentsData[commentId] ? (
                <CommentComponent
                  key={commentId}
                  commentId={commentId}
                  commentsData={commentsData || {}}
                  displayName={displayName}
                  handleDeleteComment={handleDeleteComment}
                  handleDeleteReply={handleDeleteReply}
                  handleUpvote={handleUpvote}
                  handleAddReply={handleAddReply}
                  isAnonymous={isAnonymous}
                  openReplies={openReplies}
                  setOpenReplies={setOpenReplies}
                  showReplyBox={showReplyBox}
                  setShowReplyBox={setShowReplyBox}
                  newReply={newReply}
                  replyId={replyId}
                  setNewReply={setNewReply}
                />
              ) : null,
            )}
          {visibleCommentsCount < sortedComments.length && (
            <Button onClick={showMoreComments} className='my-3'>
              Show More <FaChevronDown />
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};
